import styled from 'styled-components';

export const StyledTrial = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
  padding-bottom: 200px;
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
  .row-1 {
    font-weight: 700;
    font-size: 58px;
    line-height: 65px;
    color: #434d56;
    strong {
      font-weight: 700;
      color: #007cf6;
    }
    .pl-2 {
      padding-left: 30px;
    }
    @media (max-width: 1025px) {
      font-size: 40px;
      line-height: 44px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 44px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 22px;
      .pl-2 {
        padding-left: 10px;
      }
    }
  }
  .row-2 {
    margin-top: 100px;
    position: relative;
    z-index: 5;
    .title {
      font-weight: 700;
      font-size: 58px;
      line-height: 65px;
      color: #007cf6;
    }
    .desc {
      font-weight: 400;
      font-size: 30px;
      line-height: 41px;
      letter-spacing: 0.5px;
      color: #1f1f1f;
      margin-top: 16px;
      max-width: 516px;
      /* margin: auto; */
    }
    @media (max-width: 1025px) {
      .title {
        font-size: 40px;
        line-height: 44px;
      }
      .desc {
        font-size: 26px;
        line-height: 36px;
      }
    }
    @media (max-width: 768px) {
      margin-top: 50px;
      .title {
        font-size: 28px;
        line-height: 44px;
      }
      .desc {
        font-size: 18px;
        line-height: 36px;
      }
    }
    @media (max-width: 576px) {
      margin-top: 50px;
      .title {
        font-size: 16px;
        line-height: 22px;
      }
      .desc {
        font-size: 12px;
        line-height: 16px;
        margin-top: 2px;
      }
    }
  }
`;

StyledTrial.Container = styled.div`
  padding-top: 100px;
  @media (max-width: 768px) {
    padding: 50px 20px 0 20px;
  }
`;

export const ButtonTrial = styled.button`
  display: block;
  height: 134px;
  padding: 0 40px;
  color: #ffffff;
  background: #f6bc03;
  border: 1px solid #F6BC03;
  border-radius: 100px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 58px;
  line-height: 38px;
  margin-top: 70px;
  @media (max-width: 1025px) {
    height: 100px;
    font-size: 40px;
  }
  @media (max-width: 768px) {
    height: 64px;
    font-size: 24px;
    margin-top: 40px;
  }
  @media (max-width: 576px) {
    padding: 0 22px;
    height: 36px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 16px;
  }
`;

// eslint-disable-next-line import/no-unresolved
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import Home from 'pages/Home';
import GlobalStyle from 'styles/global-styled';

const App = () => {
  return (
    <ParallaxProvider scrollAxis="vertical">
      <BrowserRouter>
        <GlobalStyle />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          {/* <Route path="*" component={NotFoundPage} /> */}
        </Switch>
      </BrowserRouter>
    </ParallaxProvider>
  );
};

export default App;

import styled from 'styled-components';

export const StyledBannerDesktop = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #007df5;
  position: relative;
`;

StyledBannerDesktop.Text = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  img {
    max-width: 320px;
    max-height: 100%;
  }
`;

StyledBannerDesktop.YellowBG = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  height: 100%;
  width: 30%;
  background: #f6bc03;
`;

StyledBannerDesktop.Container = styled.div`
  position: relative;
  padding: 0 65px;
  height: 100%;
  .ant-row {
    position: relative;
    height: 100%;
    z-index: 4;
  }
  .welcome {
    /* max-width: 532px; */
    margin: 0 auto;
  }
`;

StyledBannerDesktop.Desc = styled.div`
  max-width: 514px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 25px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
`;

StyledBannerDesktop.BtnGroup = styled.div`
  padding-bottom: 40px;
  gap: 32px;
  @media (max-width: 1025px) {
    img {
      width: 50%;
    }
  }
`;

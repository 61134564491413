import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledFilterButtonTabs,
  StyledFilterButton,
  StyledAvatar,
} from './styled';

const FilterButtonAvatar = (props) => {
  const { options, selected, onSelect, ...other } = props;

  return (
    <StyledFilterButtonTabs {...other}>
      {options?.map((option) => (
        <StyledFilterButton
          isSelected={selected === option.id}
          onClick={() => onSelect(option.id)}
          key={option.name}
        >
          <StyledAvatar className="img" bgImg={option.img} />
        </StyledFilterButton>
      ))}
    </StyledFilterButtonTabs>
  );
};

FilterButtonAvatar.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  onSelect: PropTypes.func,
};

export default FilterButtonAvatar;

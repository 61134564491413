import { createGlobalStyle } from 'styled-components';
import NUNITO400 from 'assets/fonts/Nunito/Nunito-Regular.ttf';
import NUNITO500 from 'assets/fonts/Nunito/Nunito-Medium.ttf';
import NUNITO600 from 'assets/fonts/Nunito/Nunito-SemiBold.ttf';
import NUNITO700 from 'assets/fonts/Nunito/Nunito-Bold.ttf';
import NUNITO800 from 'assets/fonts/Nunito/Nunito-ExtraBold.ttf';

const styled = { createGlobalStyle };

const GlobalStyle = styled.createGlobalStyle`
  @font-face {
    font-family: 'Poppins';
    src: url(${NUNITO400});
    font-weight: 400;
  }
  @font-face {
    font-family: 'Nunito';
    src: url(${NUNITO500});
    font-weight: 500;
  }
  @font-face {
    font-family: 'Nunito';
    src: url(${NUNITO600});
    font-weight: 600;
  }
  @font-face {
    font-family: 'Nunito';
    src: url(${NUNITO700});
    font-weight: 700;
  }
  @font-face {
    font-family: 'Nunito';
    src: url(${NUNITO800});
    font-weight: 800;
  }

  * {
    font-family: 'Nunito', sans-serif;
  }

  body {
    background-color: #fff;
    color: #ffffff;
    font-size: 16px;
    img {
      height: auto;
      max-width: 100%;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .wrap-end {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
  }

  .wrap-start {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .d-flex {
    display: flex;
    align-items: center;
  }

  .d-none {
    display: none;
  }

  .text-center {
    text-align: center;
  }

  .img-full {
    max-width: 100%;
    max-height: 100%;
  }

  .cursor-p {
    cursor: pointer;
  }
`;

export default GlobalStyle;

import React from 'react';
import Icon from '@ant-design/icons';

const iconAndroidSvg = () => (
  <svg
    width="40"
    height="48"
    viewBox="0 0 40 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_386_5428)">
      <path
        d="M0.782258 1.72166C0.290586 2.2823 0 3.15223 0 4.27885V44.5305C0 45.659 0.290586 46.5271 0.782258 47.0877L0.907328 47.2187L21.8399 24.6704V24.4047V24.1389L0.907328 1.58881L0.782258 1.72166Z"
        fill="url(#paint0_linear_386_5428)"
      />
      <path
        d="M28.8163 32.1893L21.8399 24.6708V24.4051V24.1394L28.818 16.6226L28.9751 16.719L37.2427 21.7788C39.6024 23.2238 39.6024 25.5881 37.2427 27.0349L28.9751 32.0947L28.8163 32.1893Z"
        fill="url(#paint1_linear_386_5428)"
      />
      <g filter="url(#filter1_d_386_5428)">
        <path
          d="M28.9752 32.094L21.8383 24.4062L0.782364 47.0892C1.55961 47.9774 2.84536 48.0865 4.2934 47.202L28.9752 32.094Z"
          fill="url(#paint2_linear_386_5428)"
        />
      </g>
      <path
        d="M28.9752 16.7178L4.2934 1.61165C2.84536 0.725331 1.55961 0.836344 0.782364 1.72444L21.84 24.4074L28.9752 16.7178Z"
        fill="url(#paint3_linear_386_5428)"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_386_5428"
        x="0"
        y="0.85"
        width="39.0125"
        height="47.113"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_386_5428"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_386_5428"
          result="effect2_dropShadow_386_5428"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_386_5428"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_386_5428"
        x="0.782364"
        y="24.2562"
        width="28.1928"
        height="23.5568"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_386_5428"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_386_5428"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_386_5428"
        x1="19.9843"
        y1="3.85297"
        x2="-10.475"
        y2="32.1298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00A0FF" />
        <stop offset="0.0066" stop-color="#00A1FF" />
        <stop offset="0.2601" stop-color="#00BEFF" />
        <stop offset="0.5122" stop-color="#00D2FF" />
        <stop offset="0.7604" stop-color="#00DFFF" />
        <stop offset="1" stop-color="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_386_5428"
        x1="40.3146"
        y1="24.4051"
        x2="-0.566702"
        y2="24.4051"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE000" />
        <stop offset="0.4087" stop-color="#FFBD00" />
        <stop offset="0.7754" stop-color="#FFA500" />
        <stop offset="1" stop-color="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_386_5428"
        x1="25.0995"
        y1="28.5812"
        x2="-16.2074"
        y2="66.9281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF3A44" />
        <stop offset="1" stop-color="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_386_5428"
        x1="-4.5207"
        y1="-11.675"
        x2="13.924"
        y2="5.44803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#32A071" />
        <stop offset="0.0685" stop-color="#2DA771" />
        <stop offset="0.4762" stop-color="#15CF74" />
        <stop offset="0.8009" stop-color="#06E775" />
        <stop offset="1" stop-color="#00F076" />
      </linearGradient>
    </defs>
  </svg>
);

const iconIosWhite = () => (
  <svg
    width="41"
    height="47"
    viewBox="0 0 41 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.2442 24.9953C34.2944 21.3672 36.3835 17.9343 39.6978 16.034C37.6069 13.2492 34.1049 11.4835 30.4614 11.3772C26.5753 10.9968 22.8078 13.5459 20.8275 13.5459C18.8089 13.5459 15.7599 11.4149 12.4767 11.478C8.19712 11.6069 4.20744 13.876 2.12539 17.3654C-2.35032 24.5922 0.988073 35.2131 5.27543 41.0547C7.42048 43.9152 9.92745 47.1103 13.2077 46.997C16.4177 46.8729 17.6166 45.0882 21.4913 45.0882C25.33 45.0882 26.4548 46.997 29.8017 46.9251C33.246 46.8729 35.4163 44.0519 37.486 41.1643C39.0273 39.1262 40.2132 36.8735 41 34.4898C36.9522 32.8932 34.2489 29.0942 34.2442 24.9953Z"
      fill="white"
    />
    <path
      d="M27.9225 7.53369C29.8005 5.43114 30.7258 2.72849 30.5016 0C27.6324 0.281 24.982 1.55997 23.0786 3.58188C21.2174 5.55741 20.2486 8.21245 20.4318 10.8367C23.3021 10.8643 26.1238 9.62004 27.9225 7.53369Z"
      fill="white"
    />
  </svg>
);

const iconIosBlack = () => (
  <svg
    width="41"
    height="47"
    viewBox="0 0 41 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.2442 24.9953C34.2944 21.3672 36.3835 17.9343 39.6978 16.034C37.6069 13.2492 34.1049 11.4835 30.4614 11.3772C26.5753 10.9968 22.8078 13.5459 20.8275 13.5459C18.8089 13.5459 15.7599 11.4149 12.4767 11.478C8.19712 11.6069 4.20744 13.876 2.12539 17.3654C-2.35032 24.5922 0.988073 35.2131 5.27543 41.0547C7.42048 43.9152 9.92745 47.1103 13.2077 46.997C16.4177 46.8729 17.6166 45.0882 21.4913 45.0882C25.33 45.0882 26.4548 46.997 29.8017 46.9251C33.246 46.8729 35.4163 44.0519 37.486 41.1643C39.0273 39.1262 40.2132 36.8735 41 34.4898C36.9522 32.8932 34.2489 29.0942 34.2442 24.9953Z"
      fill="black"
    />
    <path
      d="M27.9225 7.53369C29.8005 5.43114 30.7258 2.72849 30.5016 0C27.6324 0.281 24.982 1.55997 23.0787 3.58188C21.2174 5.55741 20.2487 8.21245 20.4318 10.8367C23.3022 10.8643 26.1238 9.62004 27.9225 7.53369Z"
      fill="black"
    />
  </svg>
);

export const IconAndroid = (props) => (
  <Icon component={iconAndroidSvg} {...props} />
);

export const IconIos = (props) => <Icon component={iconIosWhite} {...props} />;

import React from 'react';
import { StyledButtonDownload } from './styled';
import { IconAndroid, IconIos } from 'components/Icons/Icons';

const ButtonDownload = ({ platform = 'ios' }) => {
  const contentIos = () => {
    return (
      <>
        <span className="icon ios">
          <IconIos />
        </span>
        <span className="label">
          Download on the
          <b>App Store</b>
        </span>
      </>
    );
  };

  const contentAndroid = () => {
    return (
      <>
        <span className="icon">
          <IconAndroid />
        </span>
        <span className="label">
          Get in on
          <b>Google Play</b>
        </span>
      </>
    );
  };

  return (
    <StyledButtonDownload platform={platform}>
      {platform === 'ios' && contentIos()}
      {platform === 'android' && contentAndroid()}
    </StyledButtonDownload>
  );
};

export default ButtonDownload;

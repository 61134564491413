import { message } from 'antd';
import ParallaxEle from 'components/ParallaxEle';
import React from 'react';
import { conceptFeedbackURL } from 'variables/scriptUrl';
import { StyledConceptFeedback, FeedbackBox, Button } from './styled';

const ConceptFeedback = () => {
  const onFeedback = (value) => {
    const formData = new FormData();
    formData.append('Feedback', value);

    fetch(conceptFeedbackURL, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    })
      .then((response) => message.success('Thank you for feedback!'))
      .catch((error) => console.error(error.message));
  };
  return (
    <StyledConceptFeedback>
      <div className="main-box">
        <FeedbackBox>
          <div className="title">Do you like Biboo concept?</div>
          <div className="flex-between">
            <ParallaxEle>
              <Button.Primary onClick={() => onFeedback('Yes')}>
                Yesss
              </Button.Primary>
            </ParallaxEle>
            <ParallaxEle>
              <Button.Secondary onClick={() => onFeedback('No')}>
                Nah...
              </Button.Secondary>
            </ParallaxEle>
          </div>
        </FeedbackBox>
      </div>
    </StyledConceptFeedback>
  );
};

export default ConceptFeedback;

import React from 'react';
import { Col, Row } from 'antd';
import welcome from 'assets/images/banner/welcome.png';
import bibooSm from 'assets/images/banner/bibooSm.png';
import bibooTextLeft from 'assets/images/banner/bibooTextLeft.png';
import bibooTextRight from 'assets/images/banner/bibooTextRight.png';
import btnIos from 'assets/images/btnIos.svg';
import btnAndroid from 'assets/images/btnAndroid.svg';
import { StyledBanneMobile } from './styled';

const BannerMobile = () => {
  return (
    <StyledBanneMobile id="banner">
      <StyledBanneMobile.TextLeft>
        <img src={bibooTextLeft} alt="text" />
      </StyledBanneMobile.TextLeft>
      <StyledBanneMobile.TextRight>
        <img src={bibooTextRight} alt="text" />
      </StyledBanneMobile.TextRight>
      <StyledBanneMobile.YellowBG />
      <StyledBanneMobile.Container>
        <Row>
          <Col span={24} className="col-right">
            <img className="img-full welcome" src={welcome} alt="welcome" />
            <img className="img-full biboo flex-center" src={bibooSm} alt="biboo" />
          </Col>
          <Col span={24} className="col-left">
            <StyledBanneMobile.Desc>
              We help you raise your children to{' '}
              <strong>build creative thinking</strong> and
              <strong> discovery surrounding</strong>
            </StyledBanneMobile.Desc>
            <StyledBanneMobile.BtnGroup className="flex-center">
              <img className="img-full" src={btnIos} alt="btnIos" />
              <img className="img-full" src={btnAndroid} alt="btnAndroid" />
            </StyledBanneMobile.BtnGroup>
          </Col>
        </Row>
      </StyledBanneMobile.Container>
    </StyledBanneMobile>
  );
};

export default BannerMobile;

import React from 'react';
import { Col, Row } from 'antd';
import bibooTextVertical from 'assets/images/bibooTextVertical.png';
import welcome from 'assets/images/banner/welcome.png';
import biboo from 'assets/images/banner/biboo.png';
import btnIos from 'assets/images/btnIos.svg';
import btnAndroid from 'assets/images/btnAndroid.svg';
import { StyledBannerDesktop } from './styled';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

const BannerDesktop = () => {
  return (
    <ParallaxBanner style={{ height: '100vh' }}>
      <StyledBannerDesktop id="banner">
        <ParallaxBannerLayer expanded={false} speed={20}>
          <StyledBannerDesktop.Text>
            <img src={bibooTextVertical} alt="text" />
          </StyledBannerDesktop.Text>
          <StyledBannerDesktop.YellowBG />
        </ParallaxBannerLayer>
        <StyledBannerDesktop.Container>
          <Row align="bottom" justify="center">
            <Col span={12} className="col-left">
              <img className="img-full welcome" src={welcome} alt="welcome" />
              <StyledBannerDesktop.Desc>
                We help you raise your children to{' '}
                <strong>build creative thinking</strong> and
                <strong> discovery surrounding</strong>
              </StyledBannerDesktop.Desc>
              <StyledBannerDesktop.BtnGroup className="flex-center">
                <img className="img-full" src={btnIos} alt="btnIos" />
                <img className="img-full" src={btnAndroid} alt="btnAndroid" />
              </StyledBannerDesktop.BtnGroup>
            </Col>
            <Col span={12} className="col-right">
              <img className="img-full" src={biboo} alt="biboo" />
            </Col>
          </Row>
        </StyledBannerDesktop.Container>
      </StyledBannerDesktop>
    </ParallaxBanner>
  );
};

export default BannerDesktop;

import ava1 from 'assets/images/features/fajar.png';
import ava2 from 'assets/images/features/angela.png';
import ava3 from 'assets/images/features/putri.png';

export const feedback = [
  {
    id: 0,
    name: 'Fajar',
    img: ava1,
    imgStr: 'assets/images/features/ava1.png',
    desc: 'This app is really adorable, I found it extremely useful due to it improve my children’s creative thinking',
    star: 5,
  },
  {
    id: 1,
    name: 'Angela',
    img: ava2,
    imgStr: 'assets/images/features/ava2.png',
    desc: 'Luckily, the kids now have the apps for learning. This app is the excellent choice for them. I love it!',
    star: 5,
  },
  {
    id: 2,
    name: 'Putri',
    img: ava3,
    imgStr: 'assets/images/features/ava3.png',
    desc: 'My kid has had a lot of good experiences of drawing in BiBoo application. She said: I love BiBoo!',
    star: 5,
  },
];

import React, { useEffect, useRef, useState } from 'react';

const ParallaxEle = ({ children }) => {
  const elementRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  function handleMouseMove(event) {
    const element = elementRef.current;
    const elementRect = element.getBoundingClientRect();
    const x = (event.clientX - elementRect.left) / elementRect.width;
    const y = (event.clientY - elementRect.top) / elementRect.height;
    setPosition({ x, y });
  }

  function handleMouseLeave() {
    setPosition({ x: 0, y: 0 });
  }

  return (
    <div
      ref={elementRef}
      style={{
        transform: `translate3d(${position.x * 30}px, ${position.y * 30}px, 0)`,
        transition: 'all 0.1s',
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default ParallaxEle;

import styled from 'styled-components';

export const StyledFilterButtonTabs = styled.ul`
  display: flex;
  overflow: auto;
  margin: 0;
  padding: 0;
`;

export const StyledFilterButton = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  color: red;
  width: 80px;
  height: 80px;
  margin: 8px 16px;
  position: relative;
  &::before {
    content: '';
    margin: 10px;
    position: absolute;
    display: block;
    top: -16px;
    bottom: -16px;
    right: -16px;
    left: -16px;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: ${(props) => (props.isSelected ? 'scale(1)' : 'scale(0.5)')};
    border: ${(props) =>
    props.isSelected ? '3px #f6bc03 solid' : '3px transparent solid'};
    -webkit-border-radius: 50%;
    border-radius: 50%;
    transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
      -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
      -moz-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
      -o-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

export const StyledAvatar = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.bgImg})`};
`;

import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
`;

StyledHeader.Container = styled.div`
  padding: 0 100px;
  @media (max-width: 768px) {
    padding: 0 30px;
  }
  @media (max-width: 576px) {
    padding: 0 20px;
  }
`;

StyledHeader.Logo = styled.img`
  width: 192px;
  @media (max-width: 768px) {
    width: 132px;
  }
  @media (max-width: 576px) {
    width: 78px;
  }
`;

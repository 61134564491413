import styled from 'styled-components';

export const StyledBanneMobile = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #007df5;
  position: relative;
`;

StyledBanneMobile.TextLeft = styled.div`
  position: absolute;
  left: 0;
  bottom: 86px;
  z-index: 3;
  img {
    max-width: 70px;
    max-height: 100%;
  }
`;

StyledBanneMobile.TextRight = styled.div`
  position: absolute;
  right: 0;
  top: 130px;
  z-index: 3;
  img {
    max-width: 70px;
    max-height: 100%;
  }
`;

StyledBanneMobile.YellowBG = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  height: 100%;
  width: 30%;
  background: #f6bc03;
`;

StyledBanneMobile.Container = styled.div`
  position: relative;
  padding: 0 24px;
  max-width: 400px;
  margin: 0 auto;

  .ant-row {
    position: relative;
    padding-top: 130px;
    z-index: 4;
  }
  .welcome {
    max-width: 161px;
  }
  .biboo {
    max-width: 216px;
    margin: 0 auto;
  }
`;

StyledBanneMobile.Desc = styled.div`
  max-width: 514px;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
`;

StyledBanneMobile.BtnGroup = styled.div`
  padding-bottom: 40px;
  gap: 10px;
  img {
    width: 120px;
  }
  @media (max-width: 1025px) {
  }
`;

import React, { useMemo } from 'react';
import { Col, Form, Input, message, Row, Select } from 'antd';
import countryList from 'react-select-country-list';
import title1 from 'assets/images/form/title1.png';
import title2 from 'assets/images/form/title2.png';
import icon1 from 'assets/images/form/icon1.png';
import icon2 from 'assets/images/form/icon2.png';
import icon3 from 'assets/images/form/icon3.png';
import icon4 from 'assets/images/form/icon4.png';
import bibooFly from 'assets/images/form/bibooFly.png';
import { trialFormURL } from 'variables/scriptUrl';
import { StyledTrialForm, PerkItem, BibooFly, SubmitBtn } from './styled';
import ParallaxEle from 'components/ParallaxEle';

const TrialForm = () => {
  const [form] = Form.useForm();

  const options = useMemo(() => countryList().getData(), []);

  const onSubmit = (values) => {
    if (values) {
      const formData = new FormData();
      formData.append('Name', values.Name);
      formData.append('Email', values.Email);
      formData.append('Nation', values.Nation);
      formData.append('ChildAge', values.ChildAge);
      formData.append('Age', values.Age);

      fetch(trialFormURL, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      })
        .then((response) => {
          form.resetFields();
          message.success('Thank you for filling out your information!');
        })
        .catch((error) => console.error(error.message));
    }
  };

  return (
    <StyledTrialForm>
      <div className="container">
        <Row gutter={30}>
          <Col xs={24} sm={12}>
            <img className="title" src={title1} alt="You will get" />
            <StyledTrialForm.Box>
              <PerkItem className="flex-start">
                <img src={icon1} />
                <span>Tools to develop creativity skill</span>
              </PerkItem>
              <PerkItem className="flex-start">
                <img src={icon2} />
                <span>
                  Resources to make your kids confident & ready for drawing and
                  coloring
                </span>
              </PerkItem>
              <PerkItem className="flex-start">
                <img src={icon3} />
                <span>Meaningful screen time</span>
              </PerkItem>
              <PerkItem className="flex-start">
                <img src={icon4} />
                <span>No ads in-app</span>
              </PerkItem>
            </StyledTrialForm.Box>
          </Col>
          <Col xs={24} sm={12} className="col-form">
            <img className="title" src={title2} alt="Start trial" />
            <StyledTrialForm.Box>
              <Form
                layout="vertical"
                form={form}
                autoComplete="off"
                id="trial"
                onFinish={onSubmit}
              >
                <Form.Item
                  label="Your Name"
                  name="Name"
                  rules={[{ required: true }]}
                  required
                >
                  <Input placeholder="Your name..." />
                </Form.Item>
                <Form.Item
                  name="Email"
                  label="Email"
                  rules={[{ required: true, type: 'email' }]}
                  required
                >
                  <Input type="email" placeholder="Your email..." />
                </Form.Item>
                <Form.Item
                  name="Nation"
                  label="Your Nation"
                  rules={[{ required: true }]}
                  required
                >
                  <Input placeholder="Your Nation..." />
                  {/* <Select options={options} /> */}
                </Form.Item>
                <Form.Item
                  name="Age"
                  label="Age"
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Your age..." />
                </Form.Item>
                <Form.Item
                  name="ChildAge"
                  label="How old is your children?"
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Your children age..." />
                </Form.Item>
                <div className="flex-center">
                  <ParallaxEle>
                    <SubmitBtn type="submit">JOIN NOW</SubmitBtn>
                  </ParallaxEle>
                </div>
              </Form>
            </StyledTrialForm.Box>
          </Col>
        </Row>
      </div>
      <BibooFly src={bibooFly} alt="biboo-fly" />
    </StyledTrialForm>
  );
};

export default TrialForm;

import variableStyles from './variable-styles';

// --- Theme Mode --- //
const lightTheme = {
  body: variableStyles.lightTheme,
  header: '#E8773D',
  title: '#5a5b74',
  description: '#454659',
  cursorBorder: '0.5px solid #5a5b74',
};

const darkTheme = {
  body: variableStyles.darkTheme,
  header: '#E8773D',
  title: '#FFFFFF',
  description: '#b3b4cd',
  cursorBorder: '0.5px solid white',
};

export { lightTheme, darkTheme };

import styled from 'styled-components';
import bgCloud from 'assets/images/form/bgCloud.png';
import bgCloudMb from 'assets/images/form/bgCloudMb.png';

export const StyledTrialForm = styled.div`
  width: 100%;
  height: 100%;
  min-height: 960px;
  padding-top: 65px;
  background-image: url(${bgCloud});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  @media (max-width: 576px) {
    padding: 24px 20px;
    background-image: url(${bgCloudMb});
  }
  .container {
    max-width: 1280px;
    margin: 0 auto;
    .title {
      height: 102px;
      display: block;
      @media (max-width: 1025px) {
        height: 82px;
      }
      @media (max-width: 576px) {
        height: 40px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @media (max-width: 1025px) {
    background-size: 100% 100%;
    min-height: 760px;
  }
  @media (max-width: 576px) {
    min-height: 960px;
    .col-form {
      margin-top: 24px;
    }
  }
`;

StyledTrialForm.Title = styled.div`
  font-weight: 900;
  font-size: 60px;
  line-height: 82px;
  color: #ffe000;
  -webkit-text-stroke: 2px #fff;
  paint-order: stroke fill;
`;

StyledTrialForm.Box = styled.div`
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
  padding: 22px;
  border-radius: 40px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 40px;
    padding: 4px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.05) 100%
    );
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
  }
  label {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #1f1f1f;
    margin-bottom: 16px;
  }
  .ant-input {
    padding: 14px 30px;
    font-weight: 400;
    font-size: 24px;
    color: #707a83;
    border-radius: 50px;
  }

  @media (max-width: 1025px) {
    label {
      font-size: 16px;
    }
    .ant-input {
      padding: 10px 24px;
      font-size: 16px;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 67px;
    font-size: 24px;
    padding: 14px 30px;
    border-radius: 50px;
  }
  @media (max-width: 768px) {
    padding: 16px;
    label {
      font-size: 14px;
    }
    .ant-input {
      padding: 8px 24px;
      font-size: 14px;
    }
    .ant-form-item-label {
      padding: 0;
    }
    .ant-form-item {
      margin-bottom: 18px;
    }
    .ant-form-item-explain {
      /* display: none; */
      font-size: 10px;
    }
    .flex-center {
      div {
        display: contents;
      }
    }
  }
`;

export const PerkItem = styled.div`
  padding: 20px 0;
  img {
    max-width: 50px;
  }
  span {
    padding-left: 15px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #1f1f1f;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:not(:last-child) {
    border-bottom: 0.5px solid #1f1f1f;
  }
  @media (max-width: 1025px) {
    img {
      max-width: 40px;
    }
    span {
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    padding: 16px 0;
    img {
      max-width: 32px;
    }
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 330px) {
    padding: 10px 0;
    img {
      max-width: 24px;
    }
    span {
      font-size: 12px;
      padding-left: 6px;
    }
  }
`;

export const BibooFly = styled.img`
  max-width: 478px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  @media (max-width: 1025px) {
    max-width: 328px;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

export const SubmitBtn = styled.button`
  padding: 24px 36px;
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #f6bc03;
  border-radius: 60px;
  border: unset;
  cursor: pointer;
  @media (max-width: 576px) {
    padding: 12px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    border-radius: 30px;
    margin-top: 16px;
  }
`;

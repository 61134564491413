import React from 'react';
import ButtonDownload from 'components/ButtonDownload';
import bibooScreen from 'assets/images/outro/bibooScreen.png';
import btnIos from 'assets/images/btnIos.svg';
import btnAndroid from 'assets/images/btnAndroid.svg';
import { StyledOutro } from './styled';
import ResponsiveLayout from 'components/ResponsiveLayout';
import ParallaxEle from 'components/ParallaxEle';

const Outro = () => {
  const desktopView = () => {
    return (
      <StyledOutro.BtnGroup className="flex-center">
        <ParallaxEle>
          <ButtonDownload platform="ios" />
        </ParallaxEle>
        <ParallaxEle>
          <ButtonDownload platform="android" />
        </ParallaxEle>
      </StyledOutro.BtnGroup>
    );
  };

  const mobileView = () => {
    return (
      <StyledOutro.BtnGroup className="flex-center">
        <img className="img-full" src={btnIos} alt="btnIos" />
        <img className="img-full" src={btnAndroid} alt="btnAndroid" />
      </StyledOutro.BtnGroup>
    );
  };

  return (
    <StyledOutro>
      <StyledOutro.Img>
        <img className="img-full" src={bibooScreen} alt="biboo-app" />
      </StyledOutro.Img>
      <StyledOutro.Text>
        Let’s make your own world with{' '}
        <span className="blue">colorful drawing tools in Biboo!</span>
      </StyledOutro.Text>
      <ResponsiveLayout desktopView={desktopView()} mobileView={mobileView()} />
    </StyledOutro>
  );
};

export default Outro;

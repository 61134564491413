/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Affix, BackTop } from 'antd';
import { StyledLayout } from './home.styled';
import { useDarkMode } from 'hooks/useDarkMode';
import { darkTheme, lightTheme } from 'styles/theme-colors';
import { scrollTo } from 'utils';
import Header from 'components/Header/Header';
import Banner from 'components/Sections/Banner/Banner';
import Trial from 'components/Sections/Trial/Trial';
import Explore from 'components/Sections/Explore/Explore';
import Features from 'components/Sections/Features/Features';
import TrialForm from 'components/Sections/TrialForm/TrialForm';
import ConceptFeedback from 'components/Sections/ConceptFeedback/ConceptFeedback';
import Outro from 'components/Sections/Outro/Outro';
import Footer from 'components/Sections/Footer/Footer';

const Home = () => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  useEffect(() => {
    const sectionId = window.location.hash;
    if (sectionId) {
      setTimeout(() => {
        navigateScroll(sectionId.substring(1));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <ThemeProvider theme={themeMode}>
      <StyledLayout>
        <Header />
        <Banner />
        <Trial />
        <Explore />
        <Features />
        <TrialForm />
        <ConceptFeedback />
        <Outro />
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  );
};

export default Home;

import styled from 'styled-components';

export const StyledExplore = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #007cf6 0%, rgba(0, 124, 246, 0) 100%);
  position: relative;
  padding: 200px 0 100px 0;
  margin-top: -250px;
  z-index: 4;
  @media (max-width: 768px) {
    padding: 50px 0;
    margin-top: -120px;
  }
  @media (max-width: 576px) {
    padding: 50px 0 0 0;
    margin-top: -105px;
  }
`;

StyledExplore.TitleImg = styled.img`
  text-align: center;
  max-width: 772px;
  margin: 0 auto;
  margin-bottom: 50px;
  @media (max-width: 1025px) {
    max-width: 522px;
  }
  @media (max-width: 768px) {
    max-width: 332px;
  }
  @media (max-width: 567px) {
    max-width: 282px;
  }
`;

StyledExplore.ReelRow = styled.div`
  white-space: nowrap;
  /* margin: 20px; */
`;

StyledExplore.ReelItem = styled.div`
  position: relative;
  display: inline-block;
  margin: 14px 15px;
  width: 620px;
  overflow: hidden;
  border-radius: 8px;
  white-space: normal;
  filter: drop-shadow(2px 8px 0px rgba(0, 0, 0, 0.05));
  @media (max-width: 768px) {
    width: 255px;
  }
  @media (max-width: 567px) {
    width: 180px;
  }
`;

import { ParallaxBanner } from 'react-scroll-parallax';
import styled from 'styled-components';

export const StyledParallaxBanner = styled(ParallaxBanner)`
  min-height: 366px;
  .ant-back-top {
    img {
      max-width: unset;
    }
    &.bt-lg {
      bottom: 180px;
    }
    &.bt-sm {
      top: 0;
      bottom: 200px;
    }
  }
`;

export const StyledFooter = styled.footer`
  background: #000000;
  width: 100%;
  height: 100%;
  padding-top: 140px;
  @media (max-width: 1025px) {
    padding: 115px;
  }
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

StyledFooter.Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

StyledFooter.Logo = styled.div`
  width: 337px;
  img {
    width: 120px;
    padding-right: 10px;
  }
  span {
    font-weight: 700;
    font-size: 50px;
    line-height: 58px;
    color: #ffffff;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    order: 2;

    img {
      padding-bottom: 10px;
      padding-right: 0;
      width: 42px;
    }
    span {
      text-align: center;
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

StyledFooter.SocialGroup = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
  .flex-between {
    div {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  img {
    width: 50px;
  }
  @media (max-width: 768px) {
    order: 3;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-top: 60px;
    img {
      width: 30px;
    }
    .flex-between {
      div {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
`;

StyledFooter.Backtop = styled.img`
  @media (max-width: 768px) {
    order: 1;
    padding-bottom: 32px;
  }
`;

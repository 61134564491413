const xs = 480;
const sm = 576;
const md = 768;
const lg = 992;
const xl = 1200;
const xxl = 1600;

const breakpoint = {
  xsMin: xs,
  smMin: sm,
  mdMin: md,
  lgMin: lg,
  xlMin: xl,
  xxlMin: xxl,
  xsMax: sm - 1,
  smMax: md - 1,
  mdMax: lg - 1,
  lgMax: xl - 1,
  xlMax: xxl - 1,
};

export default breakpoint;

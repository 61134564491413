import styled from 'styled-components';

export const StyledOutro = styled.div`
  width: 100%;
  height: 100%;
  padding: 100px 0;
  max-width: 1280px;
  margin: 0 auto;
`;

StyledOutro.Img = styled.div`
  max-width: 686px;
  margin: 0 auto;
  margin-bottom: 70px;
  @media (max-width: 768px) {
    max-width: 416px;
    margin-bottom: 32px;
  }
  @media (max-width: 576px) {
    max-width: 200px;
    margin-bottom: 24px;
  }
`;

StyledOutro.Text = styled.div`
  max-width: 990px;
  margin: 0 auto;
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 79px;
  text-align: center;
  color: #434d56;
  .blue {
    color: #007cf6;
  }
  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    max-width: 516px;
    margin: 0 auto;
  }
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 27px;
    max-width: 275px;
  }
`;

StyledOutro.BtnGroup = styled.div`
  margin-top: 70px;
  gap: 40px;
  @media (max-width: 768px) {
    img {
      width: 180px;
    }
  }
  @media (max-width: 576px) {
    margin-top: 16px;
    gap: 10px;
    img {
      width: 110px;
    }
  }
`;

import { Affix } from 'antd';
import React from 'react';
import bibooLogo from 'assets/images/bibooLogo.svg';
import { StyledHeader } from './styled';

const Header = () => {
  return (
    <StyledHeader>
      <StyledHeader.Container>
        <StyledHeader.Logo src={bibooLogo} alt="logo" />
      </StyledHeader.Container>
    </StyledHeader>
  );
};

export default Header;

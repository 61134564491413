import React, { useState } from 'react';
import { Rate } from 'antd';
import { motion } from 'framer-motion';
import { ParallaxBanner } from 'react-scroll-parallax';
import feature1 from 'assets/images/features/feature1.png';
import feature2 from 'assets/images/features/feature2.png';
import feature3 from 'assets/images/features/feature3.png';
import feature4 from 'assets/images/features/feature4.png';
import feature5 from 'assets/images/features/feature5.png';
import feature6 from 'assets/images/features/feature6.png';
import feature7 from 'assets/images/features/feature7.png';
import biboo from 'assets/images/features/biboo.png';
import banner1 from 'assets/images/banner1.png';

import { StyledFeatures } from './styled';
import FilterButtonAvatar from 'components/FilterButtonAvatar';
import { feedback } from 'components/FilterButtonAvatar/feedback';
import { WhyChooseDesktop } from './WhyChooseDesktop';
import ResponsiveLayout from 'components/ResponsiveLayout';
import { WhyChooseMobile } from './WhyChooseMobile';

const Features = () => {
  const [index, setIndex] = useState(0);

  return (
    <StyledFeatures>
      <StyledFeatures.Title>Features Of Our App</StyledFeatures.Title>
      <StyledFeatures.Container>
        <StyledFeatures.Item>
          <img src={feature1} alt="feature1" />
          <figcaption>Interactive games</figcaption>
        </StyledFeatures.Item>
        <StyledFeatures.Item>
          <img src={feature2} alt="feature2" />
          <figcaption>Educational content</figcaption>
        </StyledFeatures.Item>
        <StyledFeatures.Item>
          <img src={feature3} alt="feature3" />
          <figcaption>Parental controls</figcaption>
        </StyledFeatures.Item>
      </StyledFeatures.Container>

      <StyledFeatures.TipsContainer>
        <div className="t-left">
          <img src={feature4} alt="feature4" />
        </div>
        <div className="t-right flex-center">
          <img className="biboo" src={biboo} alt="biboo" />
          <StyledFeatures.TipsDesc>
            “Show your kids <br />
            <span>use device smartly!</span>”
          </StyledFeatures.TipsDesc>
        </div>
      </StyledFeatures.TipsContainer>

      <ResponsiveLayout
        desktopView={<WhyChooseDesktop />}
        mobileView={<WhyChooseMobile />}
      />

      <StyledFeatures.TipsContainer2>
        <div className="t-left flex-center">
          <img className="biboo" src={biboo} alt="biboo" />
          <StyledFeatures.TipsDesc>
            “Prepare for your kids <br />
            <span>the confidence and creative thinking</span> during{' '}
            <span>their growing</span>”
          </StyledFeatures.TipsDesc>
        </div>
        <div className="t-right">
          <img className="biboo" src={feature5} alt="biboo" />
        </div>
      </StyledFeatures.TipsContainer2>

      <StyledFeatures.Title>{'Why parents <3 Biboo'}</StyledFeatures.Title>
      <StyledFeatures.FeedbackContainer>
        <div className="left">
          <img className="biboo" src={feature6} alt="biboo" />
        </div>
        <div className="right">
          <div className="btn-box">
            <FilterButtonAvatar
              options={feedback}
              selected={index}
              onSelect={setIndex}
            />
          </div>
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            transition={{ duration: 0.5 }}
            key={index}
          >
            <StyledFeatures.FeedbackContent>
              <div className="title">{feedback[index]?.name}</div>
              <div className="desc">{feedback[index]?.desc}</div>
              <Rate value={feedback[index]?.star} disabled />
            </StyledFeatures.FeedbackContent>
          </motion.div>
        </div>
      </StyledFeatures.FeedbackContainer>

      {/* <ParallaxBanner
        layers={[{ image: banner1, speed: -30 }]}
        className="aspect-[2/1] parabanner"
        style={{ aspectRatio: '2/1' }}
      /> */}

      <StyledFeatures.TipsContainer2>
        <div className="t-left flex-center">
          <img className="biboo" src={biboo} alt="biboo" />
          <StyledFeatures.TipsDesc>
            “Raise <span>the connection between members</span> in a family.”
          </StyledFeatures.TipsDesc>
        </div>
        <div className="t-right">
          <img className="biboo" src={feature7} alt="feature7" />
        </div>
      </StyledFeatures.TipsContainer2>
    </StyledFeatures>
  );
};

export default Features;

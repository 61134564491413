import React from 'react';
import bibooHi from 'assets/images/bibooHi.png';
import facebook from 'assets/images/social/facebook.png';
import insta from 'assets/images/social/insta.png';
import twitter from 'assets/images/social/twitter.png';
import youtube from 'assets/images/social/youtube.png';
import backtop from 'assets/images/icons/backtop.png';

import { StyledFooter, StyledParallaxBanner } from './styled';
import { ParallaxBannerLayer } from 'react-scroll-parallax';
import { scrollTo } from 'utils';
import ParallaxEle from 'components/ParallaxEle';

const Footer = () => {
  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  return (
    <StyledParallaxBanner>
      <ParallaxBannerLayer expanded={true} speed={10}>
        <StyledFooter>
          <StyledFooter.Container className="flex-between">
            <StyledFooter.Logo className="flex-start">
              <img src={bibooHi} alt="bibooHi" />
              <span>
                Let’s <br />
                connect!
              </span>
            </StyledFooter.Logo>
            <StyledFooter.SocialGroup>
              <p>Follow us on:</p>
              <div className="flex-between">
                <ParallaxEle>
                  <img src={facebook} alt="facebook" />
                </ParallaxEle>
                <ParallaxEle>
                  <img src={insta} alt="insta" />
                </ParallaxEle>
                <ParallaxEle>
                  <img src={twitter} alt="twitter" />
                </ParallaxEle>
                <ParallaxEle>
                  <img src={youtube} alt="youtube" />
                </ParallaxEle>
              </div>
            </StyledFooter.SocialGroup>
            <ParallaxEle>
              <StyledFooter.Backtop
                src={backtop}
                width="68px"
                height="68px"
                onClick={() => navigateScroll('banner')}
              />
            </ParallaxEle>
          </StyledFooter.Container>
        </StyledFooter>
      </ParallaxBannerLayer>
    </StyledParallaxBanner>
  );
};

export default Footer;

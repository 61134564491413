import styled from 'styled-components';

export const StyledFeatures = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
  padding: 100px 0;

  .parabanner {
    width: 100vw !important;
    left: calc(-50vw + 50%);
  }
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

StyledFeatures.Title = styled.div`
  font-weight: 700;
  font-size: 58px;
  line-height: 79px;
  text-align: center;
  color: #f6bc03;
  margin-bottom: 34px;
  @media (max-width: 1025px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
  }
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 10px;
  }
`;

StyledFeatures.Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 32px;
  @media (max-width: 576px) {
    gap: 12px;
    align-items: baseline;
  }
`;

StyledFeatures.Item = styled.figure`
  img {
    border-radius: 30px;
    padding-bottom: 35px;
    max-width: 400px;
  }
  figcaption {
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #434d56;
  }
  @media (max-width: 1025px) {
    img {
      max-width: 100%;
    }
    figcaption {
      font-size: 32px;
    }
  }
  @media (max-width: 768px) {
    img {
      padding-bottom: 16px;
    }
    figcaption {
      font-size: 20px;
      line-height: 22px;
    }
  }
  @media (max-width: 576px) {
    img {
      padding-bottom: 8px;
    }
    figcaption {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

StyledFeatures.TipsContainer = styled.div`
  padding: 100px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .t-left {
    max-width: 730px;
  }
  .t-right {
    flex-direction: column;
    margin-left: -100px;
    img {
      width: 200px;
    }
  }
  @media (max-width: 1025px) {
    .t-left {
      max-width: 480px;
    }
    .t-right {
      img {
        width: 120px;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 50px 0;
    .t-left {
      max-width: 330px;
    }
    .t-right {
      margin-left: -60px;
      img {
        width: 90px;
      }
    }
  }
  @media (max-width: 576px) {
    .t-left {
      max-width: 200px;
    }
    .t-right {
      margin-left: -30px;
      img {
        width: 48px;
      }
    }
  }
`;

StyledFeatures.TipsDesc = styled.div`
  padding: 40px 60px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(50px);

  border-radius: 50px;
  font-weight: 700;
  font-size: 50px;
  line-height: 56px;
  text-align: center;
  color: #434d56;

  span {
    color: #007cf6;
    margin: 0;
  }

  @media (max-width: 1025px) {
    padding: 24px 42px;
    font-size: 28px;
    line-height: 44px;
  }
  @media (max-width: 768px) {
    padding: 20px 32px;
    font-size: 24px;
    line-height: 28px;
    border-radius: 30px;
  }
  @media (max-width: 576px) {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 19px;
    border-radius: 12px;
  }
`;

StyledFeatures.TipsContainer2 = styled.div`
  padding: 100px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .t-left {
    flex-direction: column;
    margin-right: -100px;
    img {
      width: 200px;
    }
  }
  @media (max-width: 1025px) {
    .t-left {
      img {
        width: 120px;
      }
    }
    .t-right {
      max-width: 480px;
    }
  }
  @media (max-width: 768px) {
    padding: 50px 0;
    .t-right {
      max-width: 330px;
    }
  }
  @media (max-width: 576px) {
    .t-left {
      margin-right: -50px;

      img {
        width: 48px;
      }
    }
    .t-right {
      max-width: 200px;
    }
  }
`;

StyledFeatures.ChooseContainer = styled.div`
  .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    color: #007cf6;
    max-width: 437px;
  }
  .desc {
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 0.5px;
    color: #1f1f1f;
    max-width: 437px;
  }
  .py-2 {
    padding: 50px 0;
  }
  .ant-col {
    img {
      max-width: 505px;
    }
  }

  .col-right {
    padding-left: 40px;
  }

  @media (max-width: 1025px) {
    .title {
      font-size: 32px;
      line-height: 44px;
    }
    .desc {
      font-size: 24px;
    }
    .ant-col {
      img {
        max-width: 385px;
      }
    }
    .col-left {
      padding-left: 40px;
    }
  }
  @media (max-width: 768px) {
    .title {
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }
    .desc {
      font-size: 18px;
      max-width: 400px;
      margin: 0 auto;
    }
    .ant-col {
      text-align: center;
      img {
        /* max-width: 285px; */
      }
    }
    .col-top {
      padding-bottom: 16px;
    }
    .py-2 {
      padding: 32px 0;
    }
  }
  @media (max-width: 576px) {
    .title {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }
    .desc {
      font-size: 12px;
      line-height: 16px;
      max-width: 220px;
      margin: 0 auto;
    }
    .ant-col {
      text-align: center;
      img {
        max-width: 220px;
      }
    }
    .py-2 {
      padding: 24px 0;
    }
  }
`;

StyledFeatures.FeedbackContainer = styled.div`
  padding: 100px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .left {
    padding-right: 140px;
    img {
      max-width: 625px;
    }
  }
  .right {
    width: 516px;
  }
  @media (max-width: 1025px) {
    .left {
      padding-right: 40px;
      img {
        max-width: 525px;
      }
    }
    .right {
      width: 416px;
    }
  }
  @media (max-width: 768px) {
    padding: 50px 0;
    flex-direction: column;
    .left {
      padding-right: 0;
      img {
        max-width: 405px;
      }
    }
    .right {
      width: 416px;
    }
    .btn-box {
      padding-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 576px) {
    padding: 24px 0;
    .left {
      img {
        max-width: 300px;
        border-radius: 20px;
      }
    }
    .right {
      width: 100%;
    }
  }
`;

StyledFeatures.FeedbackContent = styled.div`
  padding: 10px;
  .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    color: #007cf6;
    margin-top: 30px;
  }
  .desc {
    font-weight: 400;
    font-size: 29px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: #1f1f1f;
    margin-bottom: 32px;
  }
  @media (max-width: 1025px) {
    .title {
      font-size: 28px;
    }
    .desc {
      font-size: 22px;
    }
  }
  @media (max-width: 768px) {
    text-align: center;
    .title {
      font-size: 24px;
      margin-top: 0;
    }
    .desc {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
  @media (max-width: 576px) {
    .title {
      font-size: 18px;
      margin-top: 0;
    }
    .desc {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }
`;

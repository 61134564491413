import React, { useRef } from 'react';
import { useParallax } from 'react-scroll-parallax';
import { Col, Row } from 'antd';
import title from 'assets/images/explore/title.png';
import explore1 from 'assets/images/explore/explore1.png';
import explore2 from 'assets/images/explore/explore2.png';
import explore3 from 'assets/images/explore/explore3.png';
import explore4 from 'assets/images/explore/explore4.png';
import explore5 from 'assets/images/explore/explore5.png';
import explore6 from 'assets/images/explore/explore6.png';
import explore7 from 'assets/images/explore/explore7.png';
import explore8 from 'assets/images/explore/explore8.png';
import explore9 from 'assets/images/explore/explore9.png';
import explore10 from 'assets/images/explore/explore10.png';
import explore11 from 'assets/images/explore/explore11.png';
import explore12 from 'assets/images/explore/explore12.png';

import { StyledExplore } from './styled';

const Explore = () => {
  const target = useRef(null);

  const parallax1 = useParallax({
    easing: 'easeInOutQuad',
    translateX: [50, -50],
    speed: 0,
    targetElement: target.current,
  });
  const parallax2 = useParallax({
    easing: 'easeInOutQuad',
    translateX: [-50, 50],
    speed: 0,
    targetElement: target.current,
  });

  return (
    <StyledExplore >
      <StyledExplore.TitleImg className="flex-center" src={title} alt="title" />
      <StyledExplore.ReelRow ref={parallax1.ref}>
        <StyledExplore.ReelItem>
          <img src={explore1} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore2} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore3} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore4} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore5} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore6} />
        </StyledExplore.ReelItem>
      </StyledExplore.ReelRow>
      <StyledExplore.ReelRow ref={parallax2.ref}>
        <StyledExplore.ReelItem>
          <img src={explore7} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore8} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore9} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore10} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore11} />
        </StyledExplore.ReelItem>
        <StyledExplore.ReelItem>
          <img src={explore12} />
        </StyledExplore.ReelItem>
      </StyledExplore.ReelRow>
    </StyledExplore>
  );
};

export default Explore;

import React from 'react';
import { Col, Row } from 'antd';
import choose1 from 'assets/images/features/choose1.png';
import choose2 from 'assets/images/features/choose2.png';
import choose3 from 'assets/images/features/choose3.png';
import choose4 from 'assets/images/features/choose4.png';
import choose5 from 'assets/images/features/choose5.png';
import choose6 from 'assets/images/features/choose6.png';
import { StyledFeatures } from './styled';

export const WhyChooseDesktop = () => {
  return (
    <StyledFeatures.ChooseContainer>
      <StyledFeatures.Title>Why should you choose Biboo?</StyledFeatures.Title>
      <Row align="middle" className="py-2">
        <Col span={12} className="col-left">
          <img src={choose1} alt="choose1" />
        </Col>
        <Col span={12} className="col-right">
          <div className="title">Improve the awareness</div>
          <div className="desc">Best experience at anytime, anywhere</div>
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className="py-2">
        <Col span={12} className="col-left">
          <div className="title">Enhance learning experience</div>
          <div className="desc">Fun to learn, happy to play with family</div>
        </Col>
        <Col span={12} className="col-right">
          <img src={choose2} alt="choose2" />
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className="py-2">
        <Col span={12} className="col-left">
          <img src={choose3} alt="choose3" />
        </Col>
        <Col span={12} className="col-right">
          <div className="title">Protective enviroment</div>
          <div className="desc">
            A safe, secure, and monitored enviroment for your child to learn
            without any worries
          </div>
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className="py-2">
        <Col span={12} className="col-left">
          <div className="title">Brain development</div>
          <div className="desc">
            Your kid’s brain will be developed, and their thinking ability will
            also be upgraded through each game
          </div>
        </Col>
        <Col span={12} className="col-right">
          <img src={choose4} alt="choose4" />
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className="py-2">
        <Col span={12} className="col-left">
          <img src={choose5} alt="choose3" />
        </Col>
        <Col span={12} className="col-right">
          <div className="title">Promotion Gift</div>
          <div className="desc">Get attractive rewards from promotions</div>
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className="py-2">
        <Col span={12} className="col-left">
          <div className="title">Personalized learning</div>
          <div className="desc">
            Personaliaed learning plants tailored to meet each individual’s need
            and level of understanding
          </div>
        </Col>
        <Col span={12} className="col-right">
          <img src={choose6} alt="choose6" />
        </Col>
      </Row>
    </StyledFeatures.ChooseContainer>
  );
};

import React from 'react';
import { Col, Row } from 'antd';
import biboo1 from 'assets/images/trial/biboo1.png';
import biboo2 from 'assets/images/trial/biboo2.png';
import { ButtonTrial, StyledTrial } from './styled';
import ParallaxEle from 'components/ParallaxEle';

const Trial = () => {
  return (
    <StyledTrial>
      <StyledTrial.Container>
        <Row className="row-1" align="middle" justify="center" gutter={0}>
          <Col span={12}>
            <img className="img-full" src={biboo1} alt="biboo" />
          </Col>
          <Col span={12} className="pl-2">
            The perfect place to <strong>create your own world</strong>
            {' & '}
            <strong>build any story you like</strong>
            <ParallaxEle>
              <ButtonTrial>Start Free Trial</ButtonTrial>
            </ParallaxEle>
          </Col>
        </Row>
        <Row className="row-2" align="middle" justify="center" gutter={0}>
          <Col span={12}>
            <div className="title">For kids ages 3 - 6</div>
            <div className="desc">
              Parent-approved drawing tools in one easy app
            </div>
            <ParallaxEle>
              <ButtonTrial>Start Free Trial</ButtonTrial>
            </ParallaxEle>
          </Col>
          <Col span={12}>
            <img className="img-full" src={biboo2} alt="biboo" />
          </Col>
        </Row>
      </StyledTrial.Container>
    </StyledTrial>
  );
};

export default Trial;

// scrollTo.js

import { animateScroll } from './animateScroll';

const getElementPosition = (element) => element.offsetTop;

export const scrollTo = ({ id, ref = null, duration = 1500 }) => {
  // the position of the scroll bar before the user clicks the button
  const initialPosition = window.scrollY;

  // decide what type of reference that is
  // if neither ref or id is provided  set element to null
  const element = ref ? ref.current : id ? document.getElementById(id) : null;

  if (!element) {
    return;
  }

  animateScroll({
    targetPosition: getElementPosition(element),
    initialPosition,
    duration
  });
};

import styled from 'styled-components';
import bibooFig from 'assets/images/concept/bibooFig.png';

export const StyledConceptFeedback = styled.div`
  width: 100%;
  height: 100%;
  padding: 100px 0;
  max-width: 1280px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 24px 0;
  }
  .main-box {
    background-image: url(${bibooFig});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    min-height: 1173px;
    position: relative;
    @media (max-width: 1025px) {
      min-height: 953px;
    }
    @media (max-width: 768px) {
      min-height: 780px;
      overflow: hidden;
      background-size: 100%;
      .flex-between {
        div {
          display: contents;
        }
      }
    }
    @media (max-width: 576px) {
      min-height: 420px;
      overflow: hidden;
      background-size: 100%;
    }
    @media (max-width: 330px) {
      min-height: 310px;
    }
  }
`;

export const FeedbackBox = styled.div`
  position: absolute;
  top: 300px;
  left: 47%;
  transform: translate(-50%, -50%);

  padding: 68px 30px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(40px);
  border-radius: 48px;
  @media (max-width: 768px) {
    padding: 24px 20px;
    top: 250px;
  }
  @media (max-width: 576px) {
    padding: 24px 20px;
    top: 100px;
    left: 50%;
    max-width: 300px;
  }
  .title {
    font-weight: 700;
    font-size: 45px;
    line-height: 61px;
    text-align: center;
    color: #434d56;
    margin-bottom: 50px;
    @media (max-width: 1025px) {
      font-size: 32px;
      line-height: 44px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 576px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
  .flex-between {
    gap: 16px;
  }
`;

export const Button = styled.button`
  height: 94px;
  width: 255px;
  border-radius: 60px;
  font-weight: 700;
  font-size: 38px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  border: unset;
  cursor: pointer;
  @media (max-width: 1025px) {
    font-size: 24px;
    width: 175px;
    height: 84px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    height: 64px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 16px;
    height: 32px;
  }
`;

Button.Primary = styled(Button)`
  background: #f6bc03;
`;

Button.Secondary = styled(Button)`
  background: rgba(31, 31, 31, 0.1);
`;

import React from 'react';
import ResponsiveLayout from 'components/ResponsiveLayout';
import BannerDesktop from './BannerDesktop';
import BannerMobile from './BannerMobile';

const Banner = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<BannerDesktop {...props} />}
      mobileView={<BannerMobile {...props} />}
    />
  );
};

export default Banner;

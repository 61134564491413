import styled from 'styled-components';

export const StyledButtonDownload = styled.button`
  cursor: pointer;
  height: 107px;
  width: 350px;
  padding: 30px 0;
  border-radius: 60px;
  border: 2px solid #000000;
  background: #000000;
  color: #ffffff;
  transition: color 0.4s, background-color 0.4s, fill 0.4s;
  &:hover {
    background: transparent;
    color: #000000;
    .ios {
      svg {
        path {
          fill: black;
          transition: fill 0.4s;
        }
      }
    }
  }

  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1;
  text-align: left;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  .label {
    font-weight: 600;
    font-size: 12px;
    padding-left: 12px;
    b {
      display: block;
      margin: 4px 0 0 0;
      font-size: 24px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
`;
